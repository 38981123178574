/** ダブルクリック待ち時間(ms) */
export const DOUBLE_CLICK_WAIT = 2000;

export const DELIVER_LIST = {
    /** 1ページに表示する件数 */
    PER_PAGE: 10
};

export const DELIVERY_STATUS = {
    /** 納品済 */
    COMPLETE: '3',
    /** ダウンロード済 */
    DOWNLOADED: '4'
};

export const DOWNLOAD_STATUS = {
    /** 未ダウンロード */
    NOT_DONE: '0',
    /** ダウンロード済 */
    DOWNLOADED: '1'
};

/** 企業情報の未購入項目value */
export const NOT_PURCHASED = 999999999999999;

/** ダッシュボード評点レンジ */
export const RATING_RANGE: DistributionRange[] = [
    { min: -1, max: -1 },
    { min: 0, max: 39 },
    { min: 40, max: 44 },
    { min: 45, max: 49 },
    { min: 50, max: 64 },
    { min: 65, max: 100 },
];
/** ダッシュボードリスクスコアレンジ */
export const RISK_RANGE: DistributionRange[] = [
    { min: -1, max: -1 },
    { min: 1, max: 10 },
    { min: 11, max: 20 },
    { min: 21, max: 49 },
    { min: 50, max: 100 }
];

// 上場市場
export const JJ_SJU_NAME = {
    B: "プライム",
    C: "スタンダード",
    D: "グロース",
    E: "東証ＰＲＯ",
    F: "札証",
    G: "アンビシャス",
    H: "名証プレミア",
    I: "名証メイン",
    J: "名証ネクスト",
    K: "福証",
    L: "Ｑ－Ｂｏａｒｄ",
    M: "Ｊ－ＲＥＩＴ",
    N: "福証ＰＲＯ",
    8: "その他上場",
    9: "未上場"
}
// 税込引区分
export const GYSI_ZIKB_NAME = {
    0: "",
    1: "税込"
}
// 財務
export const ZUKB_NAME = {
    0: "無",
    1: "有"
}
// 性別
export const SEBT_KBN_NAME = {
    0: "女",
    1: "男"
}
// 倒産経歴
export const TSN_KRK_KBN_NAME = {
    0: "無",
    1: "有"
}
